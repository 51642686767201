<template>
  <v-tooltip
    open-on-focus
    :right="$vuetify.breakpoint.mdAndUp"
    :top="!$vuetify.breakpoint.mdAndUp"
    color="info"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-autocomplete
          ref="proveedor"
          :items="entries"
          :loading="isLoading"
          @keyup="search"
          v-model="model"
          hide-no-data
          item-text="nombre"
          item-value="id"
          label="Nombre proveedor *"
          return-object
          outlined
          clearable
          v-bind="$attrs"
        />
      </div>
    </template>
    <span>Escriba el nombre del proveedor y luego selecciónelo</span>
  </v-tooltip>
</template>
<script>
const debounce = (func, wait = 300) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export default {
  name: "AutocompleteProveedorComponent",
  props: ["value"],
  data: () => ({
    entries: [],
    isLoading: false,
    model: null,
  }),
  watch: {
    model(newValue) {
      this.$emit("input", newValue);
      if (newValue === null) {
        this.entries = [];
        return;
      }
      if (typeof newValue === "object" && Object.keys(newValue).length === 0) {
        this.entries = [];
      }
    },
  },
  methods: {
    initData(model_param, entries_param) {
      this.model = model_param;
      this.entries = entries_param;
    },
    findOrCreate: debounce(async (nombre, these) => {
      const response = await these.services.SolicitudCompra.getProveedores({
        nombre,
      });
      if (response.status === 200) {
        const proveedores = response?.data?.proveedores;
        if (Array.isArray(proveedores) && proveedores.length === 0) {
          these.entries.length = 0;
          these.proveedorModel = { nombre, id: null };
          these.entries.push({ nombre, id: null });
        } else {
          these.entries = proveedores;
        }
        these.isLoading = false;
      }
    }, 300),
    busquedaRetardada(value) {
      this.findOrCreate(value, this);
    },
    search(value) {
      const val = value.target.value;
      this.isLoading = true;
      if (typeof val == "string" && val.trim()) {
        if (!!val.trim) {
          this.entries = [];
        }
        this.busquedaRetardada(val);
        return;
      }
      this.isLoading = false;
    },
  },
  mounted() {
    if (typeof this.value === "object" && this.value?.id) {
      this.entries = [this.value];
      this.model = this.value;
    }
  },
};
</script>
