<template>
  <section>
    <v-card class="pa-6 bgMinsal elevation-0">
      <v-card-title class="secondary white--text mb-8">
        <span> Formulario de nuevo insumo </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="7">
            <v-text-field
              label="Nombre del artículo *"
              outlined
              v-model="form.nombre"
              :error-messages="nombreError"
              @blur="$v.form.nombre.$touch"
              @input="$v.form.nombre.$touch"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Enlace del artículo *"
              outlined
              hint="Sólo se aceptan enlaces de sitios seguros"
              v-model="form.enlace"
              :error-messages="enlaceError"
              @blur="$v.form.enlace.$touch"
              @input="$v.form.enlace.$touch"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="7">
            <autocomplete-proveedores
              ref="autoProvider"
              v-model="form.id_proveedor"
              :error-messages="proveedorError"
              @blur="$v.form.id_proveedor.$touch"
              @input="$v.form.id_proveedor.$touch"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              label="Tipo OBS *"
              outlined
              :items="tipo_obs"
              v-model="form.id_tipo_obs"
              item-text="nombre"
              item-value="id"
              clearable
              @change="getSegmento()"
              :error-messages="ObsError"
              @blur="$v.form.id_tipo_obs.$touch"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              label="Segmento *"
              outlined
              :items="segmentos"
              v-model="form.id_segmento"
              item-text="nombre"
              item-value="id"
              :disabled="form.id_tipo_obs == null"
              clearable
              @change="getProductos()"
              :error-messages="segmentoError"
              @blur="$v.form.id_segmento.$touch"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="8">
            <v-autocomplete
              label="Producto *"
              :disabled="form.id_segmento == null"
              outlined
              :items="productos"
              v-model="form.id_obs"
              item-text="nombre"
              item-value="id"
              :error-messages="productoError"
              @blur="$v.form.id_obs.$touch"
              @input="$v.form.id_obs.$touch"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              label="Unidad de medida *"
              outlined
              :items="unidadMedidas"
              v-model="form.id_unidad_medida"
              item-text="nombre"
              item-value="id"
              :error-messages="unidadMedidaError"
              @blur="$v.form.id_unidad_medida.$touch"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Cantidad *"
              outlined
              v-model="form.cantidad"
              :error-messages="cantidadError"
              @blur="$v.form.cantidad.$touch"
              @input="$v.form.cantidad.$touch"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="4">
            <v-text-field
              label="Monto total *"
              outlined
              v-model="form.monto"
              prepend-icon=""
              prepend-inner-icon="mdi-currency-usd"
              :error-messages="montoError"
              @blur="$v.form.monto.$touch"
              @input="$v.form.monto.$touch"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-textarea
            class="mx-3 mt-3"
            label="Especificación *"
            placeholder="Ingrese la especificación del insumo"
            outlined
            auto-grow
            clearable
            v-model="form.especificacion"
            :error-messages="especificacionError"
            @blur="$v.form.especificacion.$touch"
            @input="$v.form.especificacion.$touch"
          />
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              label="Aclaraciones y observaciones"
              outlined
              auto-grow
              clearable
              v-model="form.aclaraciones"
              :error-messages="aclaracionesError"
              @blur="$v.form.aclaraciones.$touch"
              @input="$v.form.aclaraciones.$touch"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-center justify-sm-start flex-column flex-sm-row pl-sm-3">
        <v-btn
          class="button-extra text-capitalize mt-4 mt-sm-0 mx-0 mr-sm-3"
          color="secondary"
          outlined
          @click="$router.back()"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="button-extra text-capitalize mt-4 mt-sm-0 mx-0 ml-sm-3"
          color="secondary"
          dark
          @click="guardar()"
        >
          Agregar
        </v-btn>
      </v-card-actions>
    </v-card>
  </section>
</template>

<script>
import MoneyTextFieldComponent from "../../components/MoneyTextFieldComponent.vue";
import AutocompleteProveedores from "../Solicitud/components/AutocompleteProveedoresComponent.vue";
import {
  required,
  requiredIf,
  numeric,
  helpers,
} from "vuelidate/lib/validators";
import { mapState } from "vuex";

const txtArea = helpers.regex(
  "txtArea",
  /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 ()/.,\n:;-]*$/
);
const currency = helpers.regex(
  "currency",
  /^(\d{1,3}(\,\d{3})*|(\d+))(\.\d{1,2})?$/
);
// Validación personalizada para sitios web
const url = helpers.regex(
  "url",
  /^(https:\/\/www\.|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,8}(:[0-9]{1,8})?(\/.*)?$/
);
const initHttps = helpers.regex("initHttps", /^(http?:\/\/|https?:\/\/)/);
export default {
  components: { MoneyTextFieldComponent, AutocompleteProveedores },
  data: () => ({
    unidadMedidas: [],
    tipo_obs: [],
    segmentos: [],
    productos: [],
    form: {
      nombre: null,
      enlace: null,
      id_proveedor: null,
      id_tipo_obs: null,
      id_segmento: null,
      id_obs: null,
      id_unidad_medida: null,
      cantidad: null,
      monto: null,
      especificacion: null,
      aclaraciones: null,
    },
    id_solicitud_compra_linea: null,
  }),
  validations: {
    form: {
      nombre: { required },
      enlace: { required, url,
        initHttps, },
      id_proveedor: { required },
      id_tipo_obs: { required },
      id_segmento: {
        required: requiredIf(function () {
          return this.form.id_tipo_obs != null;
        }),
      },
      id_obs: {
        required: requiredIf(function () {
          return this.form.id_segmento != null;
        }),
      },
      id_unidad_medida: { required },
      cantidad: { required, numeric },
      monto: { required, currency },
      especificacion: { required, txtArea },
      aclaraciones: { txtArea },
    },
  },
  computed: {
    ...mapState(["selectedUnidad"]),
    nombreError() {
      const errors = [];
      if (!this.$v.form.nombre.$dirty) return errors;
      !this.$v.form.nombre.required &&
        errors.push("Nombre del artículo es requerido");
      return errors;
    },
    enlaceError() {
      const errors = [];
      if (!this.$v.form.enlace.$dirty) return errors;
      !this.$v.form.enlace.required &&
        errors.push("Enlace del artículo es requerido");
      !this.$v.form.enlace.url && errors.push("El sitio web es inválido");
      !this.$v.form.enlace.initHttps &&
        errors.push("El sitio web debe iniciar con (http://) o (https://)");
      return errors;
    },
    proveedorError() {
      const errors = [];
      if (!this.$v.form.id_proveedor.$dirty) return errors;
      !this.$v.form.id_proveedor.required &&
        errors.push("Proveedor es requerido");
      return errors;
    },
    ObsError() {
      const errors = [];
      if (!this.$v.form.id_tipo_obs.$dirty) return errors;
      !this.$v.form.id_tipo_obs.required &&
        errors.push("Tipo de OBS es requerido");
      return errors;
    },
    segmentoError() {
      const errors = [];
      if (!this.$v.form.id_segmento.$dirty) return errors;
      !this.$v.form.id_segmento.required &&
        errors.push("Debe de seleccionar un segmento");
      return errors;
    },
    productoError() {
      const errors = [];
      if (!this.$v.form.id_obs.$dirty) return errors;
      !this.$v.form.id_obs.required && errors.push("Producto es requerido");
      return errors;
    },
    unidadMedidaError() {
      const errors = [];
      if (!this.$v.form.id_unidad_medida.$dirty) return errors;
      !this.$v.form.id_unidad_medida.required &&
        errors.push("Unidad de medida es requerido");
      return errors;
    },
    cantidadError() {
      const errors = [];
      if (!this.$v.form.cantidad.$dirty) return errors;
      !this.$v.form.cantidad.required && errors.push("Cantidad es requerido");
      !this.$v.form.cantidad.numeric &&
        errors.push("Cantidad debe de ser números");
      return errors;
    },
    montoError() {
      const errors = [];
      if (!this.$v.form.monto.$dirty) return errors;
      !this.$v.form.monto.required && errors.push("Monto total es requerido");
      !this.$v.form.monto.currency && errors.push("Monto total es inválido");
      return errors;
    },
    especificacionError() {
      const errors = [];
      if (!this.$v.form.especificacion.$dirty) return errors;
      !this.$v.form.especificacion.required &&
        errors.push("Especificación es requerido");
      !this.$v.form.especificacion.txtArea &&
        errors.push("Caracteres no permitidos");
      return errors;
    },
    aclaracionesError() {
      const errors = [];
      if (!this.$v.form.aclaraciones.$dirty) return errors;
      !this.$v.form.aclaraciones.txtArea &&
        errors.push("Caracteres no permitidos");
      return errors;
    },
  },
  methods: {
    async getUnidadMedidas() {
      const response = await this.services.Presentations.getListPresentations();
      if (response.status === 200) {
        this.unidadMedidas = response.data;
      }
    },
    async getTipoObs() {
      const response = await this.services.Obs.getObsCategoriaList();
      if (response.status === 200) {
        this.tipo_obs = response.data;
      }
    },
    
    async getSegmento() {
      const response = await this.services.CompraLinea.getSegmentos({
        tipo_obs: this.form.id_tipo_obs,
      });
      if (response.status === 200) {
        this.segmentos = response.data;
      }
    },
    
    async getProductos() {
      if (this.form.id_segmento === null) {
        this.productos = [];
      } else {
        const response = await this.services.CompraLinea.getProductoSegmento(
        this.form.id_segmento
      );
        if (response.status === 200)  this.productos = response.data;;
      }
    },

    async guardar() {
      this.$v.$touch();
      if (!this.$v.$invalid) {

        let data = {};
        data.id_solicitud_compra_linea = Number(
          this.$route.params.id_solicitud
        );
        data.nombre = this.form.nombre;
        data.enlace = this.form.enlace;
        data.id_proveedor_web = this.form?.id_proveedor?.id;
        data.nombre_proveedor = this.form?.id_proveedor?.nombre;
        data.id_obs = this.form.id_obs;
        data.id_unidad_medida = this.form.id_unidad_medida;
        data.cantidad = this.form.cantidad;
        let mon = this.form.monto.replace(",","")
        data.monto = Number(mon);
        data.especificacion = this.form.especificacion;
        data.aclaraciones = this.form.aclaraciones;
        const response = await this.services.CompraLinea.postDetalleCompra(
          this.id_solicitud_compra_linea,
          data
        )
        if (response.status === 201) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "El insumo ha sido creado exitosamente",
          });
          this.$v.$reset();
          this.$router.back();
        }

      }
    },
  },
  created() {
    this.getTipoObs();
    this.getUnidadMedidas();
    this.id_solicitud_compra_linea = Number(this.$route.params.id_solicitud);
  },
};
</script>

<style></style>
